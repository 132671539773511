@import '../../base.scss';

.main{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 20px;
    background-color: $background-grey;
}

.box{
    max-width: 1400px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.menu_link{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    text-decoration: none;
    color: $title;

    :hover{
        cursor: pointer;
        transition: .3 ease;
    }
}

.menu_text{
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    color: $dark-red;
    margin: 0;

    & span{
        color: $title;
    }
}

@media (max-width: 768px){
    .main{
        padding: 10px;
    }
}

@media(max-width: 425px){
    .menu_link{
        font-size: 14px;
    }
    
    .menu_text{
        font-size: 14px;
    }
}
