@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
}

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
}

.box_info{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}


.title{
    @include title-left;
    text-align: center;
    margin: 0;
}

.text{
    @include text;
    margin: 10px 0 0;
    text-align: justify;
}