@import '../../base.scss';

.main{
    @include main-pages;
}

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: space-between;
}

.box_map{
    max-width: 100%;
    width: 100%;
    aspect-ratio: 4/1;
}

.box{
    box-sizing: border-box;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.title{
    @include title-left;
    margin: 0;
}

.text{
    @include text;
    margin: 20px auto;
}

.button{
    @include button
}

.box_image{
    box-sizing: border-box;
    width: 45%;
}

.image{
    width: 100%;
}

.box_data{
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    padding: 50px 20px;
}

.phone_box{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.link_data{
    width: 100%;
    text-align: left;
    font-size: 20px;
    line-height: 1.25;
    font-weight: 700;
    margin:  0 0 10px;
    text-decoration: none;
    color: $title;
    &:hover{
        color: $dark-red;
        transition: .3s;
    }
}

.list{
    max-width: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.item{
    box-sizing: content-box;
}

.link_telegram{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #64a9dc;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.link{
    width: 35px;
    height: 35px;
    box-sizing: content-box;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #65bc54;

    &:hover {
        cursor: pointer;
        opacity: .7;
    }
}

.image_icon{
    width: 20px;
    height: 20px;
}

.container_form{
    @include container-row;
}

.form{
    max-width: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 50px;
    box-sizing: border-box;
}

.box_input{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 60px;
    background-color: $background-input;
    border-radius: 16px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-top: 15px;

    &:nth-child(1){
        margin-top: 0;
    }
}

.label{
    width: 100%;
    color: $text-grey;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
}

.input{
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background: none;
    border: none;
    outline: none;
    color: $white
}

.box_textarea{
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    position: relative;
    height: 100px;
    background-color: $background-input;
    border-radius: 16px;
    padding: 0 25px 10px;
    box-sizing: border-box;
    margin-top: 15px;
}

.label_textarea{
    width: 100%;
    color: $text-grey;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 8px;
    height: 80px;
    overflow: hidden;
}

.textarea{
    box-sizing: border-box;
    resize: none;
    width: 100%;
    min-height: 80px;
    background: none;
    border: none;
    outline: none;
    color: $white;
    margin-top: 5px;
}

.scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $text-grey #2F2F37;
}

.scrollbar::-webkit-scrollbar {
    width: 6px;
}

.scrollbar::-webkit-scrollbar-track {
    background-color: #2F2F37;
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: $text-grey;
}

.wrong {
    color: $red;
}

.input_error{
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: $red;
    text-align: center;
}

.button_form{
    background-color: transparent;
    color: $white;
    border: 1px solid $dark-red;
    width: 260px;
    height: 50px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    border-radius: 4px;
    margin-top: 30px;

    &:hover{
        background-color: $dark-red;
        cursor: pointer;
        transform: scale( .90 );
        transition: all .5s ease-out;
    }
}

.button_disabled{
    border: 1px solid $text-grey;
    pointer-events: none;
    cursor: default;
    color: $text-grey;
}

.text_form{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 20px 0 0;
    width: 100%;
    text-align: left; 
    color: $text-grey;
    max-width: 420px;
}

.link_form{
    text-decoration: none;
    color: $dark-red;
    &:hover{
        opacity: 0.7;
        transition: .3s;
    }
}

@media(max-width: 768px){
    .container{
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .box_map{
        aspect-ratio: 2/1;
    }

    .box{
        width: 100%;
        margin-top: 20px;
    }

    .text{
        margin: 20px auto;
    }

    .box_data{
        flex-direction: column;
        padding: 0 10px;
    }

    .box_image{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .image{
        width: 100%;
    }

    .container_form{
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .form{
        max-width: 100%;
        margin: 0;
    }
}

@media(max-width: 425px){

    .box_image{
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .image{
        width: 100%;
    }
}
