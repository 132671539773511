@import '../../base.scss';

.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    position: fixed;
    z-index: 5;
    top: 0px;
}

.header_initial{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 5;
    top: 0px;
    background-color: rgba(#c9c9c9,.7);
}

.header_scroll {
    background-color: $background-header;
}

.box{
    box-sizing: border-box;
    max-width: 1440px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 20px 10px;
}

.logo_link{
    width: 170px;
    
}

.logo{
    width: 170px;
    
}

.nav{
    max-width: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px 0 20px;
}

.link{
    color: $white;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-out;

    &:hover{
        cursor: pointer;
        transition: all .3s ease-out;
    }

    &::before{
        background-color: $dark-red;
        position: absolute;
        content: '';
        top: 20px;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: all .3s ease-out;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}

.link_page{
    color: $title;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    transition: all .3s ease-out;

    &:hover{
        cursor: pointer;
        transition: all .3s ease-out;
    }

    &::before{
        background-color: $dark-red;
        position: absolute;
        content: '';
        top: 20px;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: all .3s ease-out;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}
    .link_scroll{
        color: $title;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        text-decoration: none;
        position: relative;
        transition: all .3s ease-out;
    
        &:hover{
            cursor: pointer;
            transition: all .3s ease-out;
        }

    &::before{
        background-color: $dark-red;
        position: absolute;
        content: '';
        top: 20px;
        bottom: 0;
        width: 100%;
        height: 2px;
        transition: all .3s ease-out;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}

.button  {
    display: none;
}

.box_phone{
    min-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.link_phone{
    width: 100%;
    color: $white;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    text-decoration: none;

    &:hover{
        opacity: .7;
        transition: .3s;
    }

    &:last-child{
        margin-top: 5px;
    }
}

.link_phone_page{
    width: 100%;
    color: $menu;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    text-decoration: none;

    &:hover{
        opacity: .7;
        transition: .3s;
    }

    &:last-child{
        margin-top: 5px;
    }
}

.link_phone_scroll{
    color: $menu
}


@media(max-width: 768px){

    .box{
        padding: 5px 10px;
    }

    .logo_link{
        width: 120px;
        height: 41px;
    }

    .logo{
        width: 120px;
        height: 41px;
    }

    .nav{
        display: none;
    }

    
    .link_phone{
        font-size: 16px;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border: 0;
        background-image: url(../../image/Header/burger.svg);
        background-position: center;
        background-repeat: no-repeat;
        width: 32px;
        height: 20px;

        &:hover{
            cursor: pointer;
            filter: drop-shadow(0 0 5px $title);
            transition: all .3s ease-out;
        }
    }
}

@media(max-width: 425px){
    .box_phone{
        min-width: 150px;
    }

    .logo_link{
        width: 100px;
        height: 34px;
        
    }

    .link_phone{
        font-size: 14px;
    }

    .logo{
        width: 100px;
        height: 34px;
    }
}