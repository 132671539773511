@import '../../base.scss';

.main{
    @include main-pages;
}

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box{
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.title{
    @include title-left;
    text-align: center;
    margin-bottom: 20px
}

.text{
    @include text;
    margin: 0 auto 10px;
    text-align: justify;
}

.list{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}

.item_image{
    width: 40%;
    aspect-ratio: 1 / 1;
}

.item_box{
    box-sizing: border-box;
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.item_title{
    max-width: 500px;
    width: 100%;
    text-align: left;
    font-size: 40px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0;
    color: $dark-red;
}

.item_text{
    @include text;
    margin: 10px 0 0;
    font-size: 18px;

    & span {
        font-size: 20px;
        color: $dark-red;
    }
}

@media(max-width: 1028px){

    .item_title{
        font-size: 32px;
    }

    .item_text{
        @include text;
        margin: 10px 0 0;
        font-size: 16px;
    
        & span {
            font-size: 18px;
            color: $dark-red;
        }
    }
}


@media(max-width: 900px){

    .item_box{
        width: 60%;
    }

    .item_title{
        font-size: 25px;
    }

    .item_text{
        @include text;
        margin: 10px 0 0;
        font-size: 14px;
    
        & span {
            font-size: 16px;
            color: $dark-red;
        }
    }
}


@media(max-width: 768px){
    
    .box{
        padding: 0 10px;
    }

    .item_box{
        padding: 0 10px;
        width: 100%;
    }

    .item{
        flex-direction: column;
        align-items: center;
    }

    .item_image{
        width: 90%;
        aspect-ratio: 1 / 1;
        margin-bottom: 20px;
    }
}

@media(max-width: 425px){

    .item_image{
        width: 100%;
    }
}