@import '../../base.scss';

.main{
    @include main-pages;
}

.container{
    @include container-row;
    flex-direction: column;
    align-items: center
}

.title{
    @include block-header;
    color: $white;
    max-width: 100%
}

.subtitle{
    @include text;
    font-weight: 700;
    margin: 30px 0 0;
}

.text{
    @include text;
    margin: 10px 0 0;
}

@media(max-width: 380px){
    .title{
        font-size: 21px;
    }
}
