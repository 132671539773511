@import '../../base.scss';


@keyframes opacity {
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}


.modal{
    box-sizing: border-box;
    position: relative;
    background: transparent;
    max-height: calc(100vh - 15%);
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: $white;
    z-index: 20;
    animation: opacity 1s ease-in-out;
    padding: 0 10px;
}

.modal_active{
    opacity: 1;
    display: none;
}

.close_icon{
    position: absolute;
    top: -30px;
    right: 5px;
    background-image: url(../../image/ModalImage/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
    padding: 0;
    width: 30px;
    height: 30px;
    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $white);
        transition: .3s;
    }
}

.image{
    width: 100%;
    height: auto;
}

.box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
}

.button_left{
    position: absolute;
    width: 30px;
    height: 30px;
    left: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-image: url(../../image/ModalImage/left.png);
    background-position: center;
    background-repeat: no-repeat;
    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $white);
        transition: .3s;
    }
}

.button_right{
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-image: url(../../image/ModalImage/right.png);
    background-position: center;
    background-repeat: no-repeat;
    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $white);
        transition: .3s;
    }
}

.text{
    font-family:  "Montserrat", "Arial ", sans-serif;
    margin: 0 15px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
}

@media(max-width: 768px){
    .button_left{
        width: 20px;
        height: 20px;
        left: 10px;
    }
    
    .button_right{
        position: absolute;
        width: 20px;
        height: 20px;
        right: 10px;
    }

    .close_icon{
        top: -25px;
        right: 5px;
        width: 20px;
        height: 20px;
    }
}