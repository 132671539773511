@import '../../base.scss';

@keyframes exit-menu {
    0%{
        transform: translateX(320px);
    }
    100%{
        transform: translateX(0px);
    }
}

.main {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 320px;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $background;
    padding: 25px 20px;
    z-index: 1000;
    animation: exit-menu 1s ease-out;
}

.logo{
    width: 120px;
    height: 41px;
}

@media(max-width: 768px){

.main {
    visibility: visible;
    opacity: 1;
    }

.box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.button {
    background-image: url(../../image/Header/close.svg);
    background-position: center;
    background-repeat: no-repeat;
    padding: 0;
    border: none;
    background-color: transparent;
    transition: .3s;
    width: 32px;
    height: 32px;

    &:hover{
        cursor: pointer;
        filter: drop-shadow(0 0 5px $title);
        transition: .3s;
    }
}

.nav{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menu_link{
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    text-decoration: none;
    color: $title;
    transition: 0.3s;
    position: relative;
    margin-top: 36px;
    &:first-child {
        margin: 0;
    }
    &::before{
        background-color: $menu;
        position: absolute;
        content: '';
        bottom: 0;
        width: 100px;
        height: 2px;
        transition: 0.3s;
        transform: scaleX(0);
    }
    &:hover:before{
        transform: scaleX(1);
    }
}
}

.box_phone{
    min-width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
}

.link_phone{
    width: 100%;
    color: $dark-red;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    text-decoration: none;

    &:hover{
        opacity: .7;
        transition: .3s;
    }

    &:last-child{
        margin-top: 5px;
    }
}