

$dark-grey: #292e32;
$background-header:rgba(#c9c9c9,.8);
$text-dark-grey: #565656;;
$grey: #E3E3E3;

$white: #FFF;
$black: #000000;
$background:#e6e6e6;
$background-grey: #c9c9c9;
$dark-red:#8b0000;
$button: $dark-red;
$menu: $dark-red;
$title: #292e32;
$text-grey: #838383;
$border-form: $title;
$red:#CD2122;
$background-input: $white;

@mixin main-column {
    box-sizing: border-box;
    background-color: $background;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 50px 20px;
    overflow: hidden;

    @media(max-width: 768px){
        padding: 50px 10px;
    }

    @media(max-width: 425px){
        padding: 30px 10px;
    }
}

@mixin main-pages {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    padding: 100px 0 0;

    @media(max-width: 768px){
        padding: 70px 0 0;
    }
}

@mixin container-column {
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin container-row {
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 0 20px;

    @media(max-width: 768px){
        padding: 0 10px;
    }
}

@mixin block-header {
    max-width: 1000px;
    color: $white;
    font-size: 52px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0 0 10px;
    text-align: center;

    & span {
        color: $dark-red;
    }

    @media(max-width: 1000px){
        max-width: 730px;
        font-size: 40px;
    }

    @media(max-width: 600px){
        max-width: 350px;
        font-size: 25px;
    } 
    
}

@mixin block-text {
    color: $title;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    margin: 5px 0 0;
    text-align: center;

    @media(max-width: 700px){
        font-size: 14px;
    }    
}

@mixin text {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    margin: 20px 0;
    width: 100%;
    color: $title;
    
    @media(max-width: 425px){
        font-size: 14px;
    }    
}

@mixin text-left {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin: 20px 0 0;
    width: 100%;
    text-align: left;
    color: $title;
}

@mixin title-left {
    width: 100%;
    text-align: left;
    font-size: 40px;
    line-height: 1.25;
    font-weight: 700;
    margin: 20px 0 0;
    color: $title;

    & span {
        color: $dark-red;
    }

    @media(max-width: 768px){
        font-size: 32px;
    }

    @media(max-width: 425px){
        font-size: 25px;
    }
}

@mixin button{
    text-transform: uppercase;
    background-color: $button;
    color: $white;
    border: none;
    width: 200px;
    height: 50px;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    border-radius: 4px;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: all .5s ease-out;
    }
    
    @media(max-width: 425px){
        width: 160px;
        height: 40px;
        font-size: 14px;
    }
}

@mixin list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 5px;
    overflow: hidden;

    @media(max-width: 768px){
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 10px;
        }
}
