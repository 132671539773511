@import '../../base.scss';

.main{
    @include main-column;
    padding: 10px 10px;
}

.box{
    @include container-column;
}

.list{
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr;
}

@media(max-width: 768px){
    .main{
        padding: 10px 10px;
    }
}

@media(max-width: 425px){
    .main{
        padding: 10px 10px;
    }
}