@import '../../base.scss';

.popup{
    box-sizing: border-box;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: rgba($dark-grey, .8);
    top: 0;
    left: 0;
    right: 0;
    visibility: visible;
    opacity: 1;
    transition: 0.2s;
    z-index: 600;
    padding: 0 15px;
}