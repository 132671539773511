@import '../../base.scss';

@keyframes pulse-1 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.2);
        opacity: .3;
    }
}

@keyframes pulse-2 {
    0%{
        transform: scale( .9);
        opacity: .9;
    }
    100%{
        transform: scale(1.4);
        opacity: .3;
    }
}

.button{
    width: 80px;
    height: 80px;
    background: $dark-red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 500;
    border: none;
    padding: 0;

    &::before{
        box-sizing: border-box;
        position: absolute;
        content: '';
        width: 40px;
        height: 40px;
        background-image: url(../../image/ButtonCommunication/btn.svg);
    }

    &:hover{
        cursor: pointer;
        transition: all .3s ease;
    }
}

.pulse{
    background: $dark-red;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-1 1s infinite;
    z-index: -1;
}

.pulse_two{
    background: $dark-red;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 50%;
    animation: pulse-2 1s infinite;
    z-index: -2;
}

@media(max-width: 768px){
    .button {
        right: 15px;
        bottom: 15px;
        width: 60px;
        height: 60px;
    }

    &::before{
        width: 20px;
        height: 20px;
    }
}