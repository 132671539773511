@import '../../base.scss';

.main{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.title{
    @include title-left;
    text-align: center;
    z-index: 1;
    margin: 30px 0 40px 0;
}