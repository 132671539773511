@import '../../base.scss';

.main{
    @include main-column;
}


.title{
    @include title-left;
    text-align: center;
    z-index: 1;
    margin: 0 0 30px 0;
}

.list{
    @include list;
}

.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: transparent;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(41, 46, 50,.6);
}

.box_icon{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $dark-red;
    margin-bottom: 20px;
}


.icon{
    width: 50px;
    height: 50px;
}

.box_info{
    box-sizing: border-box;
}

.item_title{
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: $title;
    margin: 0;
    text-transform: uppercase;
}

.item_text{
    color: $text-grey;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    margin: 10px 0 0;
}