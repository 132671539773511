@import '../../base.scss';

.modal{
    box-sizing: border-box;
    position: relative;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $background;
    border-radius: 20px;
    padding: 30px;
    color: $title;
    z-index: 20;
    overflow: hidden;
}

.title{
    font-family:  "Montserrat", "Arial ", sans-serif;
    font-size: 30px;
    line-height: 34px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px;
}

.text{
    font-family:  "Montserrat", "Arial ", sans-serif;
    font-weight: 600;
    font-size: 17px;
    line-height: 1;
    text-align: center;
    color: $menu;
    margin: 15px 0 0;
}

.button{
    margin-top: 30px;
    @include button
}

