@import '../../base.scss';

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}

.container{
    box-sizing: border-box;
    background-color: transparent;
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none;
    color: $white;
    padding: 0 0 20px;
    overflow: hidden;
}

.items_wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
}
    
.items {
    flex-shrink: 0;
    display: flex;
    counter-reset: item;
    justify-content: space-around;
    min-width: 1200px;
    margin-right: 20px;
    gap: 20px;
}

.item {
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    counter-increment: item;
    transition: all 0.1s ease-in-out;
}

.marquee {
    animation: scroll 20s linear infinite;
}

.items_wrap:hover .marquee {
    animation-play-state: paused;
}

.circle{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $red;
    margin-right: 20px;
}

.text{
    font-weight: 400;
    font-size: 26px;
    line-height: 1.2;
    color: $text-grey;
    margin: 10px 0;
}