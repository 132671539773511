@import '../../base.scss';

.main{
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.image{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 254px;
    height: 127px;
}

.container{
    @include main-column;
    max-width: 1440px;
    position: relative;
}

.title{
    @include title-left;
    text-align: center;
    z-index: 1;
    margin: 0 0 30px 0;
}

.list{
    max-width: 1200px;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.item{
    min-width: 230px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba( $black, .6 );
    padding: 20px 10px;
    position: relative;
    z-index: 1;
}

.box_image{
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $dark-red;
    margin-bottom: 20px;
}


.icon{
    width: 50px;
    height: 50px;
}

.item_title{
    font-weight: 700;
    font-size: 16px;
    line-height: 1.2;
    text-align: center;
    color: $title;
    margin: 0;
    text-transform: uppercase;
}

.item_text{
    color: $text-grey;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.4;
    margin: 10px 0 0;
}


.button{
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    text-transform: uppercase;
    border: none;
    padding: 0;
    color: $white;
    max-width: 350px;
    width: 100%;
    height: 50px;
    margin: 30px 0 0;
    border-radius: 4px;
    background: $dark-red;
    z-index: 1;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: .5s;
    }
}



@media(max-width: 900px){
    button{
        max-width: 250px;
    }
    
}

@media(max-width: 768px){

    .list{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}