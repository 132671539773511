@import '../../base.scss';

.container{
    max-width: 1440px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    align-items: center;
    justify-content: space-around;
    padding-top: 20px;
}

.box{
    box-sizing: border-box;
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
}

.title{
    max-width: 500px;
    width: 100%;
    text-align: left;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 700;
    margin: 0;
    color: $dark-red;
}

.text{
    font-size: 18px;
    line-height: 1.2;
    font-weight: 600;
    margin: 10px 0 0;
    width: 100%;
    text-align: left;
    color: $title;
}

.image{
    width: 30%;
    height: auto;
}

@media(max-width: 768px){
    .title{
        font-size: 25px;
    }

    .text{
        font-size: 14px;
    }
}

@media(max-width: 600px){
    .container{
        flex-direction: column;
    }

    .box{
        width: 100%;
    }

    .image{
        width: 90%;
        height: auto;
    }
}