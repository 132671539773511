@import '../../base.scss';

.main{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.item {
    color: $white;
    background-image: url(../../image/InitialPage/img.webp);
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    
}

.overlay{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color:rgba(0, 0, 0, .1);
}

.box{
    max-width: 1200px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
    margin-top: 50px;
}

.title{
    max-width: 800px;
    font-size: 56px;
    line-height: 1.1;
    font-weight: 700;
    margin: 0;
    position: relative;
    margin-bottom: 20px;

    &::before{
        position: absolute;
        content: '';
        height: 3px;
        width: 50px;
        bottom: -20px;
        background-color: $dark-red;
    }
}

.subtitle{
    max-width: 600px;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 400;
    margin: 20px 0 40px;
    opacity: .7;
    text-align: left;
}

.button{
    background-color: $dark-red;
    text-transform: uppercase;
    color: $white;
    border: none;
    width: 200px;
    height: 50px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    border-radius: 4px;

    &:hover{
        cursor: pointer;
        transform: scale( .90 );
        transition: all .3s ease-out;
    }
}

@media(max-width: 900px){
    .title{
        max-width: 700px;
        font-size: 44px;
    }
}

@media(max-width: 768px){
    .main {
        justify-content: flex-start;
        align-items: center;
    }

    .title{
        font-size: 30px;
        max-width: 500px;
    }

    .box{
        padding: 0 10px;
    }

    .subtitle{
        font-size: 16px;
        max-width: 400px;
    }
}

@media(max-width: 425px){
    .box{
        max-width: 100%;
    }

    .title{
        max-width: 300px;
        font-size: 23px;
    }

    .subtitle{
        max-width: 300px;
        font-size: 14px;
    }

    .button{
        width: 160px;
        height: 40px;
        font-size: 14px;
    }
}